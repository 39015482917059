export default defineNuxtRouteMiddleware(async (to, from) => {
    const authStore = useAuthStore();
    const { userStateReady, hasAccountAccess } = storeToRefs(authStore);

    const onboardStore = useOnboardStore();
    const { onboardingProgress } = storeToRefs(onboardStore);
    // console.log("onboarding progress middleware: ", to.path);

    // console.log("onboarding progress middleware: ", to.path);

    if (userStateReady.value && hasAccountAccess.value) {
        console.log(
            "onboarding progress middleware userStateReady && hasAccountAccess"
        );
        console.log("onboarding progress middleware: ", to.path);
    }
    // if (to.path === "/onboard/card-upload") {
    //     console.log("onboarding progress middleware: ", to.path);
    // }
});
